<template>
  <v-container fluid pa-0>
    <v-row no-gutters class="search-zone">
      <v-col cols="12" class="px-4">
        <v-text-field
        v-model="filterZoneText"
        prepend-inner-icon="search"
        clearable
        hide-details
        label="search"
        solo
      ></v-text-field>
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col cols="12" v-for="(zone, i) in filteredZones" :key="'zone -' + i">
        <v-card class="mx-auto my-2" max-width="344">
          <v-img
            :src="zone.image"
            height="200px"
          ></v-img>
          <v-card-title> ({{zone.zcode}}) {{zone.name}} </v-card-title>
          <v-card-subtitle> {{zone.address}} </v-card-subtitle>
          <v-card-actions class="justify-end">
            <v-btn color="primary" class="elevation-0" x-large @click="startConfig(zone)"> Start config </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "ZonesList",
  components: {},
  data: () => ({
    filterZoneText:""
  }),
  computed: {
    ...mapGetters({
      zones: "pinaStore/getterZones",
    }),
    filteredZones(){
      if (this.filterZoneText == "" || this.filterZoneText == null) {
        return this.zones;
      }
      return this.zones.filter(
        (zone) =>
          zone.name.toLowerCase().includes(this.filterZoneText.toLowerCase()) ||
          zone.zcode.toLowerCase().includes(this.filterZoneText.toLowerCase())
      );
    }
  },
  watch: {
  },
  mounted() {},
  methods: {
    startConfig(zone){
      this.$store.commit("pinaStore/SET_SELECTED_ZONE", zone);
      this.$store.commit("pinaStore/SET_APP_STEP", {type:'Zone', text: zone.name,href:"/#/home/"+zone.zid});
      
      this.$router.push({ path: "/home/"+zone.zid });
    }
  },
};
</script>
<style scoped>
.search-zone {
  position: sticky;
  top: 113px;
  z-index: 2;
}
</style>
