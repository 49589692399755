<template>
  <v-container fluid pa-0>
    <v-row no-gutters>
      <zone-list></zone-list>
    </v-row>
  </v-container>
</template>

<script>
import ZoneList from "@/components/home/ZoneList.vue";
export default {
  name: "Home",
  components: {ZoneList},
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.$store.commit("pinaStore/RESET_APP_STEPS")
    });
  },
  data: () => ({
    
  })
};
</script>
<style scoped>

</style>
